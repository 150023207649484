import routerOptions0 from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/Users/ilya/Sites/spilky-landing/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}