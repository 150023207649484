import payload_plugin_e81h812gyu from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_wtauWnMWCj from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_e4o7THYuQS from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_sLFHwk1DCR from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_j5e3GYCWmR from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jit_8d43f63f01089dfdab2a5adeb371f3ce/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_R30gYFtqny from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_0ypzKEDd84 from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Fh8OK2Uqou from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_RshsLnnj8S from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_76k3KHBGNm from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/ilya/Sites/spilky-landing/.nuxt/components.plugin.mjs";
import prefetch_client_El9e6fQww0 from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import siteConfig_Nc1IsIAsVg from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@_c8c09bd9259b30e5314c986653727628/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_DiCP1sk8vB from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@_c8c09bd9259b30e5314c986653727628/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_ow72sM83b8 from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@_c8c09bd9259b30e5314c986653727628/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import _01_localization_Sjiymv6NFV from "/Users/ilya/Sites/spilky-landing/plugins/01.localization.ts";
import _02_widget_client_yEJB2c6tIw from "/Users/ilya/Sites/spilky-landing/plugins/02.widget.client.ts";
import _03_popupManager_or3NqQcoKq from "/Users/ilya/Sites/spilky-landing/plugins/03.popupManager.ts";
import defaults_oRs530GYAV from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jiti@_c8c09bd9259b30e5314c986653727628/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_e81h812gyu,
  revive_payload_client_wtauWnMWCj,
  unhead_e4o7THYuQS,
  router_sLFHwk1DCR,
  _0_siteConfig_j5e3GYCWmR,
  payload_client_R30gYFtqny,
  navigation_repaint_client_0ypzKEDd84,
  check_outdated_build_client_Fh8OK2Uqou,
  chunk_reload_client_RshsLnnj8S,
  plugin_vue3_76k3KHBGNm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_El9e6fQww0,
  siteConfig_Nc1IsIAsVg,
  inferSeoMetaPlugin_DiCP1sk8vB,
  titles_ow72sM83b8,
  _01_localization_Sjiymv6NFV,
  _02_widget_client_yEJB2c6tIw,
  _03_popupManager_or3NqQcoKq,
  defaults_oRs530GYAV
]